export function ButtonToggleSidebar({ toggleSidebar }: { toggleSidebar: () => void }) {
  return (
    <button onClick={toggleSidebar} className="md:hidden absolute top-2 left-6 z-50 p-2 rounded-md bg-[--muted]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <line x1="3" y1="12" x2="21" y2="12"></line>
        <line x1="3" y1="6" x2="21" y2="6"></line>
        <line x1="3" y1="18" x2="21" y2="18"></line>
      </svg>
    </button>
  );
}
